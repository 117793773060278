<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <div @click.stop="$router.go(-1)" class="card-header-icon">
            <b-icon class="has-text-light" size="is-small" icon="arrow-left" />
          </div>
          <p class="card-header-title has-text-light is-uppercase">ANGKATAN {{ angkatan.mulai }}</p>
        </header>
        <pspd-table v-if="isLoaded" :angkatan="angkatan"></pspd-table>
        <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
      </div>
    </div>
  </div>
</template>


<script>
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import fetchCreatedMixin from "@/apps/core/mixins/fetchCreatedMixin";
import Angkatan from "../models/angkatan.js";

export default {
  name: "AngkatanDetail",
  data() {
    this.angkatanMdl = new Angkatan();
    this.objectMdl = this.angkatanMdl;
    return this.angkatanMdl.getObservables();
  },
  components: {
    PspdTable: () => import("@/apps/programstudi/components/PspdTable.vue"),
  },
  methods: {
    fetchData() {
      this.angkatanMdl.load(this.$route.params.id);
    },
  },
  mixins: [onlineCheckMixin, fetchCreatedMixin],
};
</script>


<style scoped lang="scss">
@import "@/apps/core/assets/scss/listview.scss";
</style>